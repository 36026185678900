import axios from '../axios';

export default {
  // 메시지 목록
  getAll: params =>
    axios.get('/staff/studio_message', {
      params,
    }),

  // 개별 메시지
  get: (id, params) => axios.get(`/staff/studio_message/${id}`, { params }),

  // 메시지 생성
  create: studio_message => {
    return axios.post('/staff/studio_message', {
      studio_message,
    });
  },

  // 메시지 수정
  update: studio_message => {
    return axios.patch(`/staff/studio_message/${studio_message.id}`, {
      studio_message,
    });
  },

  // 자동 알림 발송 내역 (푸시만)
  automessage: {
    getAll: params => axios.get(`/v2/staff/message`, { params: { type: 'PUSH', ...params } }),
    getOne: (id, params) => axios.get(`/v2/staff/message/${id}`, { params: { type: 'PUSH', ...params } }),
  },
};
