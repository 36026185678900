<template>
  <div class="container">
    <div class="search-bar" ref="searchBarRef">
      <img src="/img/icons/search-icon.png" alt="검색 아이콘" />
      <input type="text" placeholder="이름 또는 전화번호로 검색" @input="changeKeyword" @focus="handleFocus" />
      <search-result :keyword="keyword" :members="members" :class="{ hide: !isOpen }" />
    </div>
  </div>
</template>

<script>
import SearchResult from './SearchResult';
import _ from 'lodash';

export default {
  components: {
    SearchResult,
  },

  data() {
    return {
      keyword: '',
      members: [],
      isOpen: false,
    };
  },

  watch: {
    keyword(newVal) {
      this.isOpen = !!newVal;
    },
  },

  created() {
    this.changeKeyword = _.debounce(this.changeKeyword, 500);
  },

  mounted() {
    window.addEventListener('click', this.handleScreenClick);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.handleScreenClick);
  },

  methods: {
    changeKeyword(e) {
      this.keyword = e.target.value;
      this.getMembers(this.keyword);
    },
    async getMembers(keyword) {
      if (!keyword) {
        this.members = [];
        return;
      }
      try {
        const result = await this.$api.member.getHeaderMembers({ search_word: keyword });
        this.members = result.data;
      } catch (error) {
        this.members = [];
      }
    },
    handleFocus() {
      if (this.keyword) this.isOpen = true;
    },
    // 영역 밖 클릭 핸들링
    handleScreenClick(e) {
      if (!this.isOpen) return;
      if (!this.$refs?.searchBarRef?.contains(e.target)) this.isOpen = false;
      e.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.search-bar {
  width: 200px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f8f8f8;
  padding: 10px 10px;
  position: relative;
  img {
    margin-right: 10px;
  }
  input {
    max-width: 80%;
    background-color: #f8f8f8;
    font-size: 12px;
    flex: 1;
    font-weight: 500;
    color: #808080;
  }
}
</style>
