<template>
  <el-dialog class="point-storage-dialog" :visible="show" @close="$emit('close', false)">
    <h3 slot="title">앱 푸시 메시지 발송 결과</h3>
    <el-table
      v-if="detailData"
      :data="detailData.targets"
      :default-sort="{ prop: 'name', order: 'ascending' }"
      :row-style="{ height: '40px' }"
      :height="resultListHeight"
      fit
    >
      <el-table-column label="발송시간" align="center" min-width="150">
        <template>
          {{ detailData.send_on | datetime }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="회원명" align="center" min-width="150">
        <div class="name-wrapper" slot-scope="scope">
          <span>{{ scope.row.member.name }}</span>
          <vaccine-image v-if="getVaccination(scope.row)" />
        </div>
      </el-table-column>
      <el-table-column label="전화번호" align="center" min-width="150">
        <template slot-scope="scope">
          {{ canViewMembersMobile ? scope.row.member.mobile : $filters.mobileMask(scope.row.member.mobile) | mobile }}
        </template>
      </el-table-column>
      <el-table-column label="결과" align="center" min-width="150">
        <span class="hint-box" slot="header">
          <p>결과</p>
          <el-tooltip effect="light" placement="bottom-end">
            <template #content>
              <div style="width: 166px">
                알림 수신거부 또는 토큰 기간 만료 등의 사유로 메시지 발송에 실패할 수 있습니다.
              </div>
            </template>
            <i class="el-icon-question" :style="{ color: '#64AEFF', fontSize: '18px' }" />
          </el-tooltip>
        </span>
        <template slot-scope="scope">
          {{ getResultMessage(scope.row) }}
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="$emit('close', false)">
        닫기
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import VaccineImage from '@/components/shared/VaccineImage.vue';
export default {
  components: { VaccineImage },
  props: {
    show: Boolean,
    close: Function,
    detailData: Object,
  },

  data() {
    return {
      resultListHeight: 0,
    };
  },

  created() {
    this.$nextTick(() => {
      this.resultListHeight = window.innerHeight * 0.45;
    });
  },

  methods: {
    getVaccination(row) {
      const vaccine = _.get(row, 'studio_user.vaccination_yn');
      return vaccine === 'Y';
    },

    getResultMessage(row) {
      if (row.status === 'sended') {
        return '발송';
      }
      if (row.status === 'failed') {
        return '실패';
      }
      return '처리중';
    },
  },
};
</script>

<style lang="scss" scoped>
.point-storage-dialog {
  /deep/ .el-dialog {
    width: 80%;
    max-width: 1024px;
    &__header,
    &__body {
      padding: 20px 30px;
    }
  }
}
.hint-box {
  display: flex;
  justify-content: center;

  p {
    margin-left: 20px;
  }
}
/deep/ .el-icon-question {
  line-height: 24px;
  margin-left: -15px;
  padding: 0 20px;
}
</style>
